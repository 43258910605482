/**
 * Module dependencies
 */
import React, { useContext } from 'react';

import { useSearch } from '../../../hooks/context';
import { StaticPropsContext } from '../../context/static-props';

/**
 * View Component
 */
const CategoriesHeader = () => {
  const { seo, header } = useSearch();
  const namespace = 'ui-category-trends-header';
  const { deviceType } = useContext(StaticPropsContext);

  const categoryName = header?.subtitle || seo.h1;

  return (
    <div className={`${namespace}-title-container ${namespace}-${deviceType}-title-container`}>
      <h1 className={`${namespace}-title ${namespace}-${deviceType}-title`}>
        {header?.title && (
          <>
            {header.title}
            <br />
          </>
        )}
        <b>{categoryName}</b>
      </h1>
    </div>
  );
};

export default CategoriesHeader;
