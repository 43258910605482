/* eslint-disable react/prop-types */
/**
 * Module dependencies
 */
const React = require('react');

const { Style } = require('nordic/style');
const { MeliGA } = require('nordic/analytics/meli-ga');
const { Hotjar } = require('nordic/hotjar');

const { SearchProvider } = require('../../../../../components/context/search');
const { StaticPropsProvider } = require('../../../../../components/context/static-props');
const Metadata = require('../../../../../components/metadata').default;
const GoogleTagManager = require('../../../../../components/google-tag-manager').default;
const useHotjarActionsByCookie = require('../../../../../hooks/use-hotjar-actions-by-cookie').default;
const { UserProvider } = require('../../../../../components/context/user');

/**
 * View Component
 */
const Category = ({
  children,
  siteId,
  cookies,
  deviceType,
  webp,
  jsDisabled,
  initialState,
  hotjar,
  country,
  currentUser,
  imagePrefix,
}) => {
  const staticProps = {
    deviceType,
    webp,
    jsDisabled,
    country,
    imagePrefix,
  };

  useHotjarActionsByCookie(hotjar);

  return (
    <>
      <Style href={`search.${deviceType}.cat.css`} critical={deviceType === 'mobile'} />
      <SearchProvider {...initialState}>
        <UserProvider value={currentUser}>
          <StaticPropsProvider value={staticProps}>
            <Metadata {...initialState.seo} {...initialState.canonical_info} />
            {children}
          </StaticPropsProvider>
        </UserProvider>
      </SearchProvider>
      <MeliGA {...initialState.analytics_track} />
      <Hotjar {...hotjar} />
      <GoogleTagManager
        siteId={siteId}
        results={initialState.results}
        platform="CAT"
        cookies={cookies}
        queryInitialState={initialState.query}
      />
    </>
  );
};

/**
 * Inject i18n context as props into View.
 */
module.exports = Category;
