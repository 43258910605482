/**
 * Module dependencies
 */
const React = require('react');

const { LazyHydrate: Hydrator } = require('nordic/lazy');

const CategoriesHeader = require('../../../../../components/header/categories/categories-header').default;
const CategoryTrendDesktopFactory =
  require('../../../../../components/category-trends-carousel/chunk-category-trend-desktop-factory').default;
const SideBar = require('../../../../../components/sidebar/sidebar.desktop').default;
const Category = require('./main.cat');
const ChunkExhibitor = require('../../../../../components/header/exhibitor/chunk-exhibitor-header.desktop.pi').default;
const SeoBlogsComponent = require('../../../../../components/seo-blog-component').default;

/**
 * View Component
 */
const SearchDesktopCAT = (props) => {
  const namespace = 'ui-category-trends-desktop';

  return (
    <Category {...props}>
      <Hydrator ssrOnly>
        <ChunkExhibitor />
      </Hydrator>
      <Hydrator ssrOnly>
        <CategoriesHeader />
      </Hydrator>
      <section className={`${namespace}-content`}>
        <div className={`${namespace}-content-main`}>
          <SideBar />
          <section className={`${namespace}-content-main-components`}>
            <CategoryTrendDesktopFactory />
          </section>
        </div>
      </section>
      <SeoBlogsComponent />
    </Category>
  );
};

/**
 * Inject i18n context as props into View.
 */
module.exports = SearchDesktopCAT;
